<template>
  <el-container>
    <el-row type="flex" justify="center" class="bg">
      <el-col :md="10" :sm="20">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2>Associa il tuo appartamento</h2>
              <h4>Inserisci il codice di association del tuo appartamento</h4>
            </div>
          </template>
          <el-form label-width="auto" :model="associationForm" status-icon :rules="rules" ref="associationForm">
            <el-form-item :label="$t('Codice dell\'Appartamento')" prop="associationCode">
              <el-input v-model="associationForm.associationCode"></el-input>
            </el-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <el-button block type="primary" @click="submit">Conferma</el-button>
            </div>
          </el-form>
        </el-card>

      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      associationForm: {
        associationCode : ""
      },
    };
  },
  computed: {
    ...mapGetters(["isLogged", "currentUser"])
  },
  methods: {
    ...mapActions(["updateApartment","fetchApartmentRelation"]),
    async submit() {
      await this.fetchApartmentRelation(this.associationForm.associationCode);
      await this.updateApartment({
        user : this.currentUser.id,
      });
      this.$message.success("Appartamento Associato");
      window.location.reload()
    },
    goTo(name) {
      this.$router.push({name})
    },
  }
}
</script>

<style scoped>
.bg {
  height: 100vh;
  background: #ededed;
  width: 100%;
  align-items: center;
}
</style>
